import React from 'react';
import { AvatarLink, Box, Col, Container, Flex, Row } from '@konrady/ui-components';

const SocialIconsMenu = props => <Flex as="ul" p={0} m={0} {...props} />;

const SocialIconsMenuItem = props => (
	<Box
		as="li"
		sx={{
			listStyle: 'none',
		}}
		{...props}
	/>
);
const socialLinks = [
	{
		href: 'https://www.facebook.com/tommmyy',
		title: 'Follow me on Facebook - Open in new window',
		children: 'F',
	},
	{
		href: 'https://twitter.com/konnnyy',
		title: 'Follow me on Twitter - Open in new window',
		children: 'T',
	},
	{
		href: 'https://www.linkedin.com/in/tomaskonrady/',
		title: 'Follow me on LinkedIn - Open in new window',
		children: 'L',
	},
];
const Footer = props => (
	<Box as="footer" mt={[3, 5]} {...props}>
		<Container>
			<Row
				alignItems="center"
				py={[3, 4]}
				flexWrap="wrap"
				flexDirection={{ xs: 'column-reverse', md: 'row' }}
			>
				<Col span={[12, 12, 6]} />
				<Col span={[12, 12, 6]}>
					<SocialIconsMenu
						mx={-2}
						mb={{ _: 3, md: 0 }}
						justifyContent={{ _: 'center', md: 'flex-end' }}
					>
						{socialLinks.map(linkProps => (
							<SocialIconsMenuItem key={linkProps.children} mx={2}>
								<AvatarLink target="_blank" rel="noopener" {...linkProps} />
							</SocialIconsMenuItem>
						))}
					</SocialIconsMenu>
				</Col>
			</Row>
		</Container>
	</Box>
);

export default Footer;
