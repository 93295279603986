import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/konrady/konrady/packages/gatsby-theme-konrady/src/templates/MdxPage.js";
import { Seo } from 'gatsby-theme-konrady';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Portfolio" mdxType="Seo" />
    <h1>{`Hello!`}</h1>
    <p>{`My name is Tomáš Konrády. I am a FE developer focused on Practical Functional Programming.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/tommmyy"
      }}>{`GitHub`}</a>{` is the best place to find me.`}</p>
    <p>{`I had been working for companies such as Lundegaard or Generali. As a team leader, I took a part in development of bank and financial applications.`}</p>
    <p>{`I also performed as a speaker at conferences like `}<a parentName="p" {...{
        "href": "https://www.reactjsvideos.com/conference/react-finland-2019"
      }}>{`React Finland`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.webexpo.net/prague2018/talk/live-demo-react-js-portals-and-modern-js-apps-for-cms"
      }}>{`WebExpo`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      